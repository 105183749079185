<template>
  <div
    id="footer"
    class="fixed-bottom"
  >
    <img
      :src="logoIluniUi"
      alt="logo_iluniui"
      class="logo-footer iluni"
    >
    <img
      :src="logoUiConnect"
      alt="logo_uiconnect_lowres"
      class="logo-footer uiconnect"
    >
    <img
      :src="logoIluniFhhi"
      alt="logo_ilunuifhui"
      class="logo-footer fisip"
    >
  </div>

</template>

<script>
import logoIluniUi from '@/assets/images/logo/iluni.png'
import logoIluniFhhi from '@/assets/images/logo/fisip.svg'
import logoUiConnect from '@/assets/images/logo/uiconnect.png'

export default {
  data() {
    return {
      logoIluniUi,
      logoIluniFhhi,
      logoUiConnect,
    }
  },
}
</script>

<style scoped >
 #footer {
   height: 4.75rem;
   line-height: 4.75rem;
   background-color: #ffffff;
   box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 0;
 }

  .logo-footer {
    height: 41px;
    margin: 0 5px;
  }

  .uiconnect {
    width: 54px;
    height: 35px;
  }

  .iluni {
    width: 30.7px;
    height: 39.51px;
  }

  .fisip {
    width: 140px;
    height: 69px;
  }
</style>
