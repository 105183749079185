<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <router-view />
    <div
      v-if="isFooter"
      class="pb-5"
    />
    <Footer v-if="isFooter" />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Footer,
  },
  computed: {
    isFooter() {
      // isFooter is true for default value
      const footer = this.$route.meta.footer === undefined || this.$route.meta.footer === true
      return footer
    },
  },
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>
